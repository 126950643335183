.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 98vh;
    font-family: 'Inter';
    background-color: #F7FF8B;
    overflow: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    font-family: 'Inter';
}

.img-container {
    display: block;
    height: 4rem;
    width: 4rem;
    max-height: 4rem;
    max-width: 4rem;
    overflow: hidden;
}

.img {
    width: 100%;
    height: 100%;
    object-fit:cover;
    overflow:hidden;
}

.title {
    font-family: 'Inter';
    font-size: 2rem;
    font-weight: 600;
    margin-top: 2rem;
}

.error {
    font-family: 'Inter';
}

.btn {
    font-family: 'Inter';
    margin-top: 1rem;
    padding: 1rem;
    background-color: #ffffff;
    outline: none;
    text-decoration: none;
    border: 2px solid #224d57;
    border-radius: 1rem;
    cursor: pointer;
    width: 10rem;
    font-size: 1.2rem;
}

.btn:hover {
    box-shadow: #C7CBCC 0px 0px 0px 1px;
}

.btn:active {
    transform: translate(0, 1px);
}